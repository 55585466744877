import React from 'react';

export default function ToastSuccessIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="10" fill="#00BB64" />
      <g clipPath="url(#clip0_7124_10870)">
        <path
          d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM10.2958 15.2928C10.6864 15.6834 11.3196 15.6834 11.7101 15.2928L17.3661 9.63599C17.7565 9.24552 17.7565 8.6125 17.3661 8.22206C16.9756 7.83157 16.3425 7.8316 15.952 8.22212L11.003 13.172L8.881 11.05C8.49053 10.6595 7.85747 10.6595 7.467 11.05C7.07653 11.4405 7.07654 12.0735 7.467 12.464L10.2958 15.2928Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_7124_10870">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
