import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from "prop-types"
// Custom Hooks
import useAuth from "../../hooks/fetch/useAuth"
import useValidateFormData from 'hooks/useValidateFormData';
// Components
import Button from '../Button/Button';
import Input from '../Input/Input';
import Logo from '../../assets/icons/Logo';
import BackButton from '../Button/BackButton';
import Header from '../Header/Header';
import FormWrapper from '../FormWrapper';

export default function ForgotPasswordForm({ callback }) {
  const { validateFormData } = useValidateFormData()
  const { loading } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({
    email: '',
  });

  const { forgot_password } = useAuth()

  const disabled = useMemo(() => {
    return !validateFormData({email: formData.email})
  },[formData])

  return (
    <section className="w-full md:px-40 md:py-[32px]">
      <Logo />
      <div className="w-full sm:max-w-[90%] mx-auto md:mt-[72px] mt-[40px]">
        <BackButton />
      </div>
      <div className="flex justify-center">
        <FormWrapper
          className='transition_left max-w-[400px] mt-0 sm:mt-[70px]'
          onSubmit={() => forgot_password(formData, callback)}
        >
          <Header
            title="Forgot Password"
            variant="form"
            description="Enter your email and we’ll send you a code to reset your password."
          />
          <div className="mb-4">
            <Input
              label="Email Address"
              type="email"
              placeholder="Email address"
              id="email"
              name="email"
              readOnly={loading}
              value={formData.email}
              onChange={(e) => setFormData({ email: e.target.value })}
            />
          </div>
          <Button
            name="Recover Password"
            theme="dark"
            disabled={!formData.email || loading || disabled}
            loading={loading}
            styles="mt-10 w-full"
          />
        </FormWrapper>
      </div>
    </section>
  );
}

ForgotPasswordForm.propTypes = {
  callback: PropTypes.func
}