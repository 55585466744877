import React from 'react';

export default function ToastErrorIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="white"
      />
      <path
        d="M10.6387 17.8775H13.4611V20.6868H10.6387V17.8775ZM10.6387 3.50391H13.4611V10.3461L13.2521 15.6079H10.858L10.6387 10.3461V3.50391Z"
        fill="#E20010"
      />
    </svg>
  );
}
