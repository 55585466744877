/* eslint-disable no-use-before-define */
import React, { useCallback } from "react";
// Custom Hooks
import useMultiStepForm from "../../hooks/useMultiStepForm";
// Components
import AuthLayout from "../../components/Layouts/AuthLayout";
import ForgotPasswordForm from "../../components/Forms/ForgotPasswordForm"
import VerifyAccountForm from "../../components/Forms/VerifyAccountForm"
import ResetPasswordForm from "../../components/Forms/ResetPasswordForm"
import { Helmet } from "react-helmet-async";

export default function ForgotPassword() {
    const moveBack = useCallback(() => {
        back()
    }, [])

    const { step, next, back } = useMultiStepForm([
        <ForgotPasswordForm
            key={0}
            callback={() => next(1)}
        />,
        <VerifyAccountForm
            key={1}
            callback={() => next(1)}
            backButton={moveBack}
            description='Enter the 6 digit code sent to your email address.'
        />,
        <ResetPasswordForm
            key={2}
            backButton={moveBack}
        />
    ])

    return (
        <>
            <Helmet>
                <title>Forgot Password - POUCHERS</title>
            </Helmet>
            <AuthLayout center={step} />
        </>
    )
}