import React from 'react';

export default function ToastCloseIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0001 8.82227L13.5359 5.28643C13.8613 4.96104 14.3889 4.96104 14.7143 5.28643C15.0397 5.61182 15.0397 6.13938 14.7143 6.46477L11.1784 10.0006L14.7143 13.5364C15.0397 13.8618 15.0397 14.3894 14.7143 14.7148C14.3889 15.0402 13.8613 15.0402 13.5359 14.7148L10.0001 11.1789L6.46428 14.7148C6.13889 15.0402 5.61133 15.0402 5.28594 14.7148C4.96056 14.3894 4.96056 13.8618 5.28594 13.5364L8.82178 10.0006L5.28594 6.46477C4.96056 6.13938 4.96056 5.61182 5.28594 5.28643C5.61133 4.96104 6.13889 4.96104 6.46428 5.28643L10.0001 8.82227Z"
        fill="#E9E6FD"
      />
    </svg>
  );
}
