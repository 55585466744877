import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
// Icons
import GuestButtonIcon from "../../assets/icons/GuestButtonIcon"
import FormWrapper from "../FormWrapper"
// components
import Button from "../Button/Button"
import Input from "../Input/Input"
import Header from "../Header/Header"
import useValidateFormData from "hooks/useValidateFormData"

export default function LoginForm({ formData, onChange, loading, onSubmit }) {
    const { validateFormData } = useValidateFormData()
    const disabled = useMemo(() => {
        // return !validateFormData({ "email_or_phone": formData?.email_or_phone }) || !validateFormData({ "password": formData?.password })
        // return !validateFormData({ "email_or_phone": formData?.email_or_phone }) || !formData?.password
        return !validateFormData({ "email_or_phone": formData?.email_or_phone }) || !validateFormData({ "password": formData?.password })
    }, [formData])

    return (
        <>
            <FormWrapper
                className='mx-auto max-w-[400px] pt-70 sm:pt-128 lg:mx-0 transition_left'
                onSubmit={onSubmit}
            >
                <Header
                    title="Login"
                    variant="form"
                    description="Welcome back, fill your details to continue."
                />
                <div className="mb-4">
                    <Input
                        label="Email or phone number"
                        type="text"
                        placeholder="Enter email or phone number"
                        id="email_or_phone"
                        name="email_or_phone"
                        readOnly={loading}
                        value={formData?.email_or_phone}
                        onChange={onChange}
                        // showError={false} 
                    />
                </div>
                <div className="mb-3.5">
                    <Input
                        label="Password"
                        type="password"
                        placeholder="Enter password"
                        id="password"
                        name="password"
                        readOnly={loading}
                        value={formData?.password}
                        onChange={onChange}
                        // showError={true}
                    />
                </div>
                <div className="w-max ml-auto">
                    <Link to="/forgot-password" className="hide_tap">
                        <p className="font-dmsans_r mb-8 text-14 text-purple font-normal">Forgot Password?</p>
                    </Link>
                </div>
                <Button
                    name="Log in"
                    theme="dark"
                    disabled={disabled || loading}
                    loading={loading}
                    styles="my-4 w-full"
                />
                <div className="text-14 text-dark_blue font-dmsans_r font-normal mb-[1.5rem]">
                    <p className="text-center mb-10">
                        Don&apos;t have an account? <Link to="/create-account" className="hide_tap">
                            <span className="text-purple font-dmsans_b">Create Account</span>
                        </Link>
                    </p>
                </div>
                <div className="text-14 sm:hidden text-dark_blue font-dmsans_r font-normal pb-8 w-max mx-auto">
                    <Link to="/guests" className="hide_tap">
                        <div className="flex items-center rounded-lg font-dmsans_r font-normal text-14 text-purple">
                            <p>Continue as Guest</p>
                            <GuestButtonIcon />
                        </div>
                    </Link>
                </div>
            </FormWrapper>
            <div className="hidden sm:block absolute top-8 right-[1rem]">
                <Link to="/guests" className="hide_Tap">
                    <div className="flex items-center bg-purple_light p-16 rounded-lg font-dmsans_r font-normal text-14 text-purple">
                        <p>Continue as Guest</p>
                        <GuestButtonIcon />
                    </div>
                </Link>
            </div>
        </>
    )
}

LoginForm.propTypes = {
    onChange: PropTypes.func,
    formData: PropTypes.object,
    loading: PropTypes.bool,
    onSubmit: PropTypes.func
}