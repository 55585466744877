import React from "react";
import PropTypes from 'prop-types';
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function RedirectIfToken({ children }) {
  const { isLoggedIn, token, loggedUser } = useSelector(state => state.auth)
  let location = useLocation();

  // Redirect user to login page if user is not logged in
  if (token && isLoggedIn && loggedUser?.tag && loggedUser?.iscreatedpin) {
    return <Navigate to="/" state={{ from: location }} replace />
  }

  return children
}

RedirectIfToken.propTypes = {
  children: PropTypes.element
};
