import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types'
// Custom Hooks
import useAuth from "../../hooks/fetch/useAuth"
import useValidateFormData from 'hooks/useValidateFormData';
//Components
import Button from '../Button/Button';
import Input from '../Input/Input';
import Logo from '../../assets/icons/Logo';
import Modal from '../Modal/Modal';
import Header from '../Header/Header';
import Success from '../Success';
import FormWrapper from '../FormWrapper';
import BackButton from 'components/Button/BackButton';

export default function ResetPasswordForm({ backButton }) {
  let navigate = useNavigate()
  const { validateFormData } = useValidateFormData()
  const [showModal, setShowModal] = useState(false)
  const { loading } = useSelector((state) => state.auth);
  const { reset_password } = useAuth()
  const [formData, setFormData] = useState({
    password: ''
  })

  const disabled = useMemo(() => {
    return !validateFormData({ password: formData.password })
  }, [formData])

  return (
    <section className="w-full md:px-40 md:py-[32px]">
      <Logo />
      <div className="w-full sm:max-w-[90%] md:mt-[72px] mt-[40px] mx-auto cursor-pointer hide_tap">
        <BackButton onClick={backButton || undefined} />
      </div>
      <FormWrapper
        className="transition_left max-w-[400px] mx-auto sm:mt-[70px]"
        onSubmit={() => reset_password(formData, () => setShowModal(true))}
      >
        <Header
          title="Set New Password"
          variant="form"
          description="Your new password must be different from previous used passwords."
        />
        <div className="mb-[11px]">
          <Input
            label="New Password"
            type="password"
            placeholder="Enter new password"
            id="password"
            name="password"
            readOnly={loading}
            value={formData.password}
            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
          />
        </div>
        <p className="text-14 text-dark_blue font-dmsans_r font-medium mb-[32px]">Password must contain alphabets and numbers</p>
        <Button
          name='Set Password'
          theme="dark"
          disabled={disabled || loading}
          loading={loading}
          styles="my-4 w-full"
        />
      </FormWrapper>
      {showModal &&
        <Modal>
          <Success
            title="Reset Successful"
            description="The password has been reset successfully."
            buttonName="Log in"
            onClick={() => navigate('/')}
          />
        </Modal>}
    </section>
  );
}

ResetPasswordForm.propTypes = {
  backButton: PropTypes.func
}