/* eslint-disable no-use-before-define */
import React, { Suspense, lazy, useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useMultiStepForm from "hooks/useMultiStepForm";
import useAuth from "hooks/fetch/useAuth";
import AuthLayout from "components/Layouts/AuthLayout";
const CreateTagForm = lazy(() => import("components/Forms/CreateTagForm"))
const VerifyAccountForm = lazy(() => import("components/Forms/VerifyAccountForm"))
const CreateTransactionPinForm = lazy(() => import("components/Forms/CreateTransactionPinForm"))
import LoginForm from "components/Forms/LoginForm";
import Spinner from "components/Spinner";
import { Helmet } from "react-helmet-async";

export default function Login() {
    let navigate = useNavigate()
    const location = useLocation()
    const { loading, loginUser } = useAuth()
    const [formData, setFormData] = useState({
        email_or_phone: '',
        password: '',
    })

    const handleChange = useCallback((e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value.trim() })
    }, [formData])

    const nextStep = useCallback(() => {
        next()
    }, [])
    const previousStep = useCallback(() => {
        back()
    }, [])

    const loginUserProxy = useCallback(() => {
        loginUser(formData, () => nextStep())
            .then((res) => {
                if (res?.code === 200) {
                    if (!res?.data?.tag) return next(2)
                    if (!res?.data?.iscreatedpin) return next(3)
                    if (location.search.split('=')[0] === "?request") sessionStorage.setItem('requestId', location.search.split('=')[1])
                }
            })
    }, [formData])

    const completed = useCallback(() => {
        navigate('/')
    }, [])

    const { step, next, back } = useMultiStepForm([
        <LoginForm
            key={0}
            callback={nextStep}
            formData={formData}
            onChange={handleChange}
            loading={loading}
            onSubmit={loginUserProxy}
        />,
        <VerifyAccountForm
            key={1}
            callback={nextStep}
            backButton={previousStep}
            email={formData?.email_or_phone}
            userStatus="new"
            description={`Please check your email, a code to verify your account was sent to ${formData?.email_or_phone}`}
        />,
        <CreateTagForm
            key={2}
            callback={nextStep}
        />,
        <CreateTransactionPinForm
            key={3}
            callback={completed}
        />
    ])

    return (
        <>
            <Helmet>
                <title>Login - POUCHERS</title>
            </Helmet>
            <AuthLayout
                left={step.key !== "1"}
                center={step.key === "1" ? step : null}
                right={step.key !== "1" ? <Suspense fallback={<Spinner />}>{step}</Suspense> : null}
            />
        </>
    )
}